<template>
  <a href="#" class="list-group-item list-group-item-action flex-column align-items-start py-4 px-4">
    <div class="d-flex w-100 justify-content-between">
      <div>
        <div class="d-flex w-100 align-items-center">
          <h5 class="mb-1">{{item.email}}</h5>
        </div>
      </div>
      <small>{{item.date}}</small>
    </div>
    <h4 class="mt-3 mb-1">{{item.subject}} </h4>
    <p class="text-sm mb-0">{{ item.text }}</p>
    <div class="d-flex w-100 justify-content-end">
      <i class="fas fa-paperclip"  v-if="item.comments && item.comments.length > 0"></i>
      <i class="ni ni-chat-round ml-1" v-if="item.comments && item.comments.length > 0"></i>
    </div>
  </a>

</template>
<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
    };
  }
};
</script>
