<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <!-- <a href="#" @click.prevent="prev" class="fullcalendar-btn-prev btn btn-sm btn-default">
            <i class="fas fa-angle-left"></i>
          </a>
          <a href="#" @click.prevent="next" class="fullcalendar-btn-next btn btn-sm btn-default">
            <i class="fas fa-angle-right"></i>
          </a>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'dayGridMonth'}"
                       @click="changeView('dayGridMonth')">
            Month
          </base-button>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'dayGridWeek'}"
                       @click="changeView('dayGridWeek')">
            Week
          </base-button>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'timeGridDay'}"
                       @click="changeView('timeGridDay')">
            Day
          </base-button> -->
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->
          <comments-card :title="$t('message.infoPool')"/>
        </div>
      </div>
    </div>
    <modal :show.sync="showDetail" v-if="currentItem">
      <h6 slot="header" class="modal-title">{{currentItem.subject}}</h6>
      <div class="mb-4">
        <div class="row">
          <div class="col-md-12">
            <div class="input-field input-disabled">
              <div class="d-flex w-100 justify-content-between ">
                <div>
                  <div class="d-flex w-100 align-items-center">
                    <h5 class="mb-1">{{currentItem.email}}</h5>
                  </div>
                </div>
                <small>{{currentItem.date}}</small>
              </div>
              <p class="text-sm mb-0">{{ currentItem.text }}</p>
            </div>

          </div>

        </div>
        <div class="row">
          <div class="col-md-12">

            <comment v-for="comment in currentItem.comments" :key="comment.id" user-image="/images/bild1.png"
                              :user-name="comment.user.name"
                              :text="comment.text"
                              :date="comment.date"
                              :like-count="3"
                              :share-count="2" />
          </div>
          <div class="col-md-12 text-center mt-3" v-if="!currentItem.comments || currentItem.comments.length === 0">
            <span class="text-muted">Noch keine Kommentare vorhanden</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-11">
          <social-input placeholder="Kommentar schreiben" v-model="content" ></social-input>

        </div>
        <div class="col-md-1">
          <base-button type="primary" class="mb-2">
            <i class="far fa-paper-plane"></i>
          </base-button>
          <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="fileList">
            <base-button type="default">
              <i class="fas fa-paperclip"></i>
            </base-button>
          </el-upload>
        </div>
      </div>
      <template slot="footer">
        <base-button type="primary" @click="showEdit = false">{{ $t('message.save') }}</base-button>
        <base-button type="link" class="ml-auto" @click="showEdit = false">{{ $t('message.close') }}</base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  import Modal from '@/components/Modal'
  import infoPoolItem from './InfoPoolItem'
  import { mapGetters } from 'vuex'
  import Comment from '@/components/Feed/Comment'
  import SocialInput from '@/components/Feed/SocialInput'
  import CommentsCard from "@/views/Dashboard/Project/BHB/DetailView/CommentsCard";

  export default {
    name: 'InfoPool',
    components: {
      Modal,
      infoPoolItem,
      Comment,
      SocialInput,
      CommentsCard
    },
    computed: {
      ...mapGetters([
        'infoPoolItems'
      ])
    },
    data() {
      return {
        currentItem: null,
        showDetail: false
      }
    },
    methods: {
      addTask(task) {
        this.$store.dispatch('addTask', task)
      },
      onItemClick(item) {
        this.currentItem = item
        this.showDetail = true
      }
    }
  };
</script>
<style lang="scss">
</style>
